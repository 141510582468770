@tailwind base;
@tailwind components;
@tailwind utilities;


.body-scrollbar::-webkit-scrollbar {
    width: 0px; /* Ancho del scrollbar */
}

.body-scrollbar::-webkit-scrollbar-track {
    background: transparent; /* Color de fondo del track */
}

.body-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb */
    border-radius: 10px; /* Bordes redondeados */
    border: 2px solid transparent; /* Espaciado */
}

.body-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color al pasar el cursor */
}

.custom-scrollbar::-webkit-scrollbar {
    width: 12px; /* Ancho del scrollbar */
    height: 10px; /* Ancho del scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent; /* Color de fondo del track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb */
    border-radius: 10px; /* Bordes redondeados */
    border: 2px solid transparent; /* Espaciado */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color al pasar el cursor */
}

.autocomplete-scrollbar::-webkit-scrollbar {
    width: 10px; /* Ancho del scrollbar */
 
}

.autocomplete-scrollbar::-webkit-scrollbar-track {
    background: transparent; /* Color de fondo del track */
}

.autocomplete-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb */
    border-radius: 0px; /* Bordes redondeados */
    border: 2px solid transparent; /* Espaciado */
}

.autocomplete-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color al pasar el cursor */
}


